function cloneElements(elements) {
    const cloned = []

    for (let i = 0; i < elements.length; i++) {
        cloned.push(elements[i].cloneNode(true))
    }

    return cloned
}

function init() {
    const carousel = document.querySelector('.vendor-carousel')
    const slider = carousel.querySelector('.vendor-carousel__slider')

    // we should calculate the number of clones we need based on the width of elements and width of container... running out of time though
    const cloned = cloneElements(Array.from(slider.children))

    cloned.forEach(clone => {
        slider.appendChild(clone)
    })

    const children = Array.from(slider.children)

    let offset = 0
    const gap = parseInt(window.getComputedStyle(slider).gap)

    children.forEach((child, i) => {
        offset += child.getBoundingClientRect().width
        offset += gap
    })

    // Create keyframe animation based on total width of children plus gaps
    const keyframes = `
    @keyframes scroll {
        0% {
            translate: 0 0;
        }
        100% {
            translate: -${offset / 2}px 0;
        }
    }
    `
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(keyframes))
    document.head.appendChild(style)

    let time = parseInt(carousel.dataset.speed)

    if (!time) {
        time = 30
    }

    slider.style.animation = `scroll ${time}s infinite linear forwards`
}

init()
